import Rails from '@rails/ujs'

$ ->
  bindRemoveButtons = () ->
    $('.user-edit-section-content').on 'click', '.user-subscription-remove-btn', (e) ->
      e.preventDefault()
      that = this
      user_email = $(this).closest('tr').find('.subscription-user-email-address').html()
      $('#user-subscription-error-warning').addClass('hidden')

      dataConfirmModal.confirm({
        title: 'Are you sure?',
        text: 'This will remove the user ' + user_email + ' from this subscription group.',
        commit: 'Remove User',
        cancel: 'Nevermind',
        zIindex: 10099,
        onConfirm: ->
          el = $(that)
          el.addClass('hidden')
          $("#"+el[0].id + "-spinner").removeClass('hidden')
          Rails.fire(document.getElementById(e.target.form.id), 'submit')
      })

  $('#unsubscribe', '#barecancel-trigger').on 'click', (e) ->
    ga_unsubscribe()

  $('#reactivate').on 'click', (e) ->
    ga_reactivate_subscription()

  $('#add-subscription-user').on 'click', (e) ->
    e.preventDefault()
    $('#user-subscription-error-warning').addClass('hidden')
    $('#add-subscription-user').prop('disabled', true)

    $('#add-subscription-user-plus').addClass('hidden')
    $('#add-subscription-user-spinner').removeClass('hidden')
    Rails.fire(document.getElementById('new_user_subscription'), 'submit')

  $('#changePasswordModal').on 'hidden.bs.modal', (e) ->
    $('.has-error').removeClass('has-error');
    $('span.text-danger').remove()
    $(this).find('input[type=password]').val('')

  $('.subscription-plan-option-row').click (e) ->
    index = $(this).data('item')
    if $(this).data('current')
      $('.change-subscription-plan-btn').addClass('disabled')
    else
      $('.change-subscription-plan-btn').removeClass('disabled')
    $('.radio-input').prop('checked', false)
    $('.order-radio-icon').removeClass('fa-dot-circle-o')
    $('.order-radio-icon').addClass('fa-circle-o')
    $('#'+index).prop('checked', true)
    $('.subscription-plan-option-row').removeClass('selected')
    $('#order-row-'+index).addClass('selected')
    $('#order-radio-'+index).removeClass('fa-circle-o')
    $('#order-radio-'+index).addClass('fa-dot-circle-o')

    $('#promotion-code').attr('name', '')
    promotionData = $(this).data('promotion')
    if typeof(promotionData) != 'undefined' && promotionData.length > 0
      $('#promotion-code').attr('name', 'subscription[promotion_code]')

  $('#change-subscription-plan-unconfirmed-submit,
     #unconfirmed-renewal-btn,
     #select-plan-button-unconfirmed').on 'click', (e) ->
    $('#emailActivateReminder').modal('show')

  bindRemoveButtons()
