// IMPORTANT! If the user is logged in, set to user_id; else, set to ''
export var _user_id = gon.current_user_id;

// IMPORTANT! Set to a unique session ID for the visitor's current browsing session.
export var _session_id = gon.session_id;

export var _sift = _sift || [];

// IMPORTANT! Insert your JavaScript snippet key here!
_sift.push(['_setAccount', gon.sift_js_key]);

_sift.push(['_setUserId', _user_id]);
_sift.push(['_setSessionId', _session_id]);
_sift.push(['_trackPageview']);

export function sift() {
  if (gon.sift_enabled) {
    var e = document.createElement('script');
    e.type = 'text/javascript';
    e.async = true;
    e.src = ('https:' === document.location.protocol ? 'https://' : 'http://') + 'cdn.siftscience.com/s.js';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(e, s);
  }
}
