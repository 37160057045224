import ConsentLibrary from '@shutterstock-private/consent-library'

(async () => {
  const consentLibrary = new ConsentLibrary({
    id: gon.consent_library_id,
    onConsentChanged: () => {
      window.location.reload();
    }
  });

  await consentLibrary.load();
})();
