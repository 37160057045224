class Resizer
  constructor: ->
    @$responsizeSearchContainer = $('.responsive-search')
    @$tsGalleryColumn = $('.ts-gallery-column')
    $(window).resize @.resizeGrid.bind(@)
    @imageMargin = parseInt(@$tsGalleryColumn.css('margin-left'))
    @maxImageWidth = parseInt(@$tsGalleryColumn.css('max-width'))

  resizeGrid: ->
    containerWidth = @$responsizeSearchContainer.width()
    @resultsPerRow = Math.ceil(containerWidth / (@maxImageWidth + @imageMargin * 2))

    # TODO: https://issues.turbosquid.com/browse/GRN-6668
    #@hidePartialRow()

    return if @totalPages() == Infinity

    innerWidth = containerWidth - (2 * @imageMargin * @resultsPerRow)
    computedImageWidth = Math.floor(innerWidth / @resultsPerRow)
    minWidth = Math.min(computedImageWidth, @maxImageWidth)

    formattedWidth = "#{minWidth}px"
    @$tsGalleryColumn
      .css("min-width",  formattedWidth)
      .css('min-height', formattedWidth)
      .css("width",      formattedWidth)
      .find(".product-search-preview-image").css("height", formattedWidth)
    return

  setLinkOffset: (direction, page)->
    $link = $("#ps-paging-#{direction}-btn")
    if $link.length
      sign = if direction == 'next' then 1 else -1
      page = page + sign
      offset = gon.offset + (sign * @visibleSize)
      href = $link.attr('href')
      href = href.replace(/(&|\?)offset=[^&]*/g, '')
      href = href.replace(/(&|\?)page=[^&]*/g, "$1page=#{page}")
      separator = if href.indexOf('?') >= 0 then '&' else '?'
      $link.attr('href', "#{href}#{separator}offset=#{offset}")

  divmod: (a, b)->
    [a // b, a %% b]

  totalPages: ->
    Math.ceil(gon.totalSize / @visibleSize)

  fixPagerForm: ->
    totalPages = @totalPages()
    [pageIndex, pageOffset] = @divmod(gon.offset, @visibleSize)
    page = if pageOffset == 0
      pageIndex + 1
    else
      totalPages += 1
      pageIndex + 2

    if totalPages > 1
      $('.search-result-paging-pre').removeClass('hidden')
      if page < totalPages
        $('#ps-paging-next-btn').removeClass('btn-paging-disabled')
      else
        $('#ps-paging-next-btn').addClass('btn-paging-disabled')

    $('#ts-page-num').val(page)
    $('#ts-total-pages').text(totalPages)

    page

  hidePartialRow: ->
    @$tsGalleryColumn.show()

    [fullRows, extra] = @divmod(gon.pageSize, @resultsPerRow)
    if @$tsGalleryColumn.length == gon.pageSize
      @$tsGalleryColumn.slice(-extra).hide() if extra

    @visibleSize = fullRows * @resultsPerRow

    if @totalPages() == Infinity
      @$tsGalleryColumn.slice(-extra).show() if extra
    else
      page = @fixPagerForm()

      @setLinkOffset('pre',  page)
      @setLinkOffset('next', page)

  addOffsetToPageBox: (e)->
    totalPages = Math.ceil(gon.totalSize / @visibleSize)
    page = $('#ts-page-num').val()
    page = Math.min(Math.max(page, 0), totalPages)
    offset = @visibleSize * (page - 1)
    $("<input type='hidden' name='offset' value='#{offset}'>").appendTo(e.target)

$ ->
  resizer = new Resizer()
  resizer.resizeGrid()

  $('form.search_pagination_box').on 'submit', (e)->
    # TODO: https://issues.turbosquid.com/browse/GRN-6668
    # resizer.addOffsetToPageBox(e)

  $('#ts-page-num').on 'click', ->
    $(this).select()

  $('#keywords, #keywords-mobile').on 'click', ->
    $(this).select()

  $('.search-form-sort-option').click ->
    if $(this).data('type') == 'default'
      $.removeCookie('sort', { 'path': '/'})
      true

  if gon.displayLogin
    $.getScript('/sessions/new', ()->
      $('#userModal').modal('show')
    )
