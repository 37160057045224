$ ->
  downloadElementsSelector = $('.download-buttons, .download-options')
  downloadElementsSelector.on 'ajax:before', '.download', (evt, data, status, xhr) ->
    $element = $(@)
    downloadType = $element.data('download-type')
    fileFormat = $element.data('file-format')
    fileView = $element.data('file-view')
    spinnerFileId = $element.data('spinner-file-id')

    if fileView?
      window.product.setView(fileView)
      window.player.displayViewer(fileView, true)

    action = {
      action: 'download',
      downloadType: downloadType,
      fileFormat: fileFormat,
      spinnerFileId: spinnerFileId
    }

    $.cookie('action', JSON.stringify(action), { path: '/; SameSite=Lax', secure: true })
