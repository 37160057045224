Modernizr.addTest('hdpi', function() {
  var dpr = window.devicePixelRatio ||

  // fallback for IE
  (window.screen.deviceXDPI / window.screen.logicalXDPI) ||

  1;

  return !!(dpr > 1);
});
