addLightboxFormSubmit = (form) ->
  $form = $(form)
  selected_lightbox_id = $form.find('select').val()
  current_action = $form.attr('action')
  $form.attr('action', current_action.replace('lightbox_id', selected_lightbox_id))
  true

$ ->
  $('#js-quick-preview-overlay, .js-lightbox-container').on 'click', '.js-btn-add-remove-photoshop', ->
    $element = $(this)
    $element.attr('disabled', true)
    $element.closest('.js-lightbox-container').find('form').submit()
