const activateIntercom = function () {
  if (window.intercomSettings === null) return;

  if (typeof window.Intercom === "function") {
    window.Intercom("reattach_activator");
    window.Intercom("update", window.intercomSettings);
  } else {
    initIntercom();
  }
};

const initIntercom = function () {
  const intercom = () => intercom.init(arguments);
  intercom.q = [];
  intercom.init = (args) => intercom.q.push(args);

  window.Intercom = intercom;
  window.addEventListener("load", attachIntercomScript, false);
};

const attachIntercomScript = () => {
  const scriptEl = document.createElement("script");
  scriptEl.type = "text/javascript";
  scriptEl.async = true;
  scriptEl.src = `https://widget.intercom.io/widget/${window.intercomSettings.app_id}`;
  document.head.append(scriptEl);
};

activateIntercom();
