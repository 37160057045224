var ProductLicenseUses = {
  extendedUse: "Extended Use",
  basicUse: "Basic Use"
};

var ViewTypes = {
  atlas: "atlas"
};

function getTypeForView(view) {
  return ViewTypes.atlas;
}

function getResolutionForView(view) {
  return '2k';
}

export { getResolutionForView };
