$(function() {
  if($('.pricing-index').length > 0) {
    ga_add_product_impressions('detail', 1, 'ecommerce', 'pricing-page');
  }

  if($('.orders-new').length > 0) {
    ga_add_product_impressions('checkout', 2, 'ecommerce', 'order-page');
    ga_selected_product_cart_action('add', 2, 'ecommerce', 'initial-cart');
  }
});
