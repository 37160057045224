// TODO: V2 version of stripe is not used anymore in the project, remove this and every call to isStripeV2
function isStripeV2() { return !!Stripe.setPublishableKey }
window.isStripeV2 = isStripeV2

$(function() {
  function createStripeElements() {
    // TODO: V2 version of stripe is not used anymore in the project, remove this and every call to isStripeV2
    if (window.isStripeV2()) { return null }
    if (!gon.stripe_publishable_key) { return null }
    if (!document.getElementById("card-element")) { return null }

    var stripe = Stripe(gon.stripe_publishable_key)
    var elements = stripe.elements()

    var card = elements.create('card', {
      style: {
        base: {
          iconColor: '#232323',
          color: '#232323',
          fontWeight: 500,
          fontFamily: '"proxima-nova", "Open Sans", helvetica, arial, sans-serif',
          fontSize: '18px',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#888'
          }
        }
      }
    })

    // Add an instance of the card UI component into the `card-element` <div>
    card.mount('#card-element')

    $('#card-element').css({
      padding: '20px 10px',
      background: 'white',
      boxShadow: 'inset 0px 1px 2px 0px rgba(0, 0, 0, 0.1)',
      borderRadius: '5px'
    })

    function createToken(extraDetails) {
      return stripe.createToken(card, extraDetails)
    }

    return {
      createToken: createToken
    }
  }

  window.stripeElements = createStripeElements()
});
