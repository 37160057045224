export class SpinnerPreview
  DEFAULT_OPTIONS =
    framerate: 10
    containerSelector: '#js-quick-preview-overlay'
    imagesSelector: '.js-spinner-images img'

  constructor: (userOptions = {})->
    @options = Object.assign(DEFAULT_OPTIONS, userOptions)

  clearInterval: =>
    clearInterval(@turntableTimer) if @turntableTimer?

  start: =>
    @stop()
    $imageItems = $(@options.containerSelector).find(@options.imagesSelector)
    @frameCounter = 0
    setSpinnerFrame = =>
      $imageItems.removeClass('active')
      $imageItem = $($imageItems[@frameCounter])
      $imageItem.addClass('active')
      @frameCounter = (@frameCounter + 1) % $imageItems.length

    if $imageItems.length
      @turntableTimer = setInterval(setSpinnerFrame, 1000 / @options.framerate)

  stop: =>
    $imageItems = $(@options.containerSelector).find(@options.imagesSelector)
    $imageItems.removeClass('active')
    $($imageItems[0]).addClass('active')
    @clearInterval()
