Q = require('q/q')
import debounce from 'lodash/debounce'

export class QuickPreview
  DEFAULT_OPTIONS =
    beforeShow: -> Q(arguments)
    afterShow:  -> Q(arguments)
    beforeHide: -> Q(arguments)
    afterHide:  -> Q(arguments)
    hoverTimeout: 300
    quickPreviewPopup:
      width: 400
      gutter: 10
    disablePopup: false
    disableOverlay: false
    itemContainerSelector: '.js-quick-preview-items-container'
    itemSelector: '.js-quick-preview-item'
    hideSelector: '.header, .nav, .footer'
    itemOverlayContentSelector: '.js-quick-preview-overlay-content'
    itemPopupContentSelector: '.js-quick-preview-popup-content'

  constructor: (userOptions = {})->
    @options = Object.assign(DEFAULT_OPTIONS, userOptions)

    @_active = false
    @quickPreviewPopup = new QuickPreviewPopup(@options.quickPreviewPopup)
    @quickPreviewOverlay = new QuickPreviewOverlay()

    $(@options.hideSelector).on 'mouseenter', @mouseLeaveHandler
    $(@options.itemContainerSelector).on 'mouseenter', @options.itemSelector, @mouseEnterHandler
    $(@options.itemContainerSelector).on 'mouseleave', @options.itemSelector, (event)=>
      @mouseLeaveHandler(event) unless @_active

    @quickPreviewOverlay.on 'mouseleave', @mouseLeaveHandler

  $elementFromEvent: (event)->
    $($(event.target).closest(@options.itemSelector)[0])

  mouseEnterHandler: (event)=>
    $element = @$elementFromEvent(event)

    callback = ($element)=>
      throw 'disabled' if @disabled()

      @$currentElement = $element
      @_cancelled = false
      [@options.beforeShow, @showQuickPreview, @options.afterShow].reduce(Q.when, Q($element))

    @_showQuickPreview = debounce(callback, @options.hoverTimeout)
    @_showQuickPreview($element)

  showQuickPreview: ($element)=>
    throw 'Wrong element' unless @$currentElement == $element
    @_active = true
    options =
      offset: $element.offset()
      width: $element.outerWidth()
      height: $element.outerHeight()
      documentWidth: $(document).width()
      documentHeight: $(document).height()
      $itemOverlayContent: $($element.find(@options.itemOverlayContentSelector))
      $itemPopupContent: $($element.find(@options.itemPopupContentSelector))

    unless @options.disableOverlay
      @quickPreviewOverlay.show(options)
      options.height = @quickPreviewOverlay.height()

    @quickPreviewPopup.show(options) unless @options.disablePopup
    Q($element)

  mouseLeaveHandler: (event)=>
    $element = @$elementFromEvent(event)
    @$currentElement = null
    @_showQuickPreview.cancel() if @_showQuickPreview?.cancel?
    [@options.beforeHide, @hideQuickPreview, @options.afterHide].reduce(Q.when, Q())

  hideQuickPreview: =>
    @_active = false
    @quickPreviewOverlay.hide()
    @quickPreviewPopup.hide()
    Q()

  disabled: ->
    $('.dropdown.open').length > 0

class QuickPreviewOverlay
  constructor: ()->
    @$quickPreviewOverlay = $('#js-quick-preview-overlay')

  height: ->
    @$quickPreviewOverlay.height()

  show: (options)->
    @$quickPreviewOverlay.show()
    @$quickPreviewOverlay.offset(options.offset)
    @$quickPreviewOverlay.width(options.width)
    content = options.$itemOverlayContent.clone()
    @$quickPreviewOverlay.html(content)
    content.show()
    @

  hide: ->
    @$quickPreviewOverlay.hide()
    @

  on: ->
    @$quickPreviewOverlay.on.apply(@$quickPreviewOverlay, arguments)

class QuickPreviewPopup
  constructor: (options)->
    @quickPreviewPopupWidth = options.width
    @quickPreviewPopupGutter = options.gutter
    @$quickPreviewPopup = $('#js-quick-preview-popup')

  leftOffset: (options)->
    yMax = options.offset.left + options.width + @quickPreviewPopupGutter + @quickPreviewPopupWidth
    fitsOnRight = options.documentWidth > yMax
    fitsOnLeft = 0 < options.offset.left - @quickPreviewPopupGutter - @quickPreviewPopupWidth

    if fitsOnRight || not fitsOnLeft
      options.offset.left + options.width + @quickPreviewPopupGutter
    else
      options.offset.left - @quickPreviewPopupGutter - @quickPreviewPopupWidth

  topOffset: (options)->
    verticalAlignCenterTopOffset = options.offset.top - (@$quickPreviewPopup.height() - options.height) / 2
    verticalAlignCenterXMax = verticalAlignCenterTopOffset + @quickPreviewPopupWidth

    fitsVerticalAlignCenter = options.documentHeight > verticalAlignCenterXMax

    if fitsVerticalAlignCenter
      verticalAlignCenterTopOffset
    else
      options.offset.top + options.height - @$quickPreviewPopup.height()

  offset: (options)->
    top: @topOffset(options), left: @leftOffset(options)

  show: (options)->
    @$quickPreviewPopup.show()
    popupContent = options.$itemPopupContent.clone()
    @$quickPreviewPopup.html(popupContent)
    popupContent.show()
    @$quickPreviewPopup.offset(@offset(options))
    @

  hide: ->
    @$quickPreviewPopup.hide()
    @
