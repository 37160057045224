import '../plugin_demo/jquery.joyride-2.1'
import * as PixelSquid from '../plugin_demo/pixelsquid-atlas'

function PluginDemo() {
  this.player = null;
  this.firstRotation = true;
}

PluginDemo.prototype.loadPlayer = function() {
  var initialImage = 'G16';

  this.loadImage(initialImage);

  //get the size of the current player window
  var playerSize = getPlayerSize();

  var that = this;
  $.ajax({
    url: '/plugin_demo/spinner.json'
  }).done(function(data) {
    var adapter = new PixelSquid.AtlasAPIAdapter();
    adapter.parseResponse(data);

    //show a static image while loading
    var asset = adapter.getAsset();

    var preferredImageSize = 600;
    //use smaller resolution images for mobile
    if (isMobile()) {
      preferredImageSize = 300;
    }

    that.player = new PixelSquid.AtlasSpriteSheetPlayer({ windowSize: playerSize, preferredImageSize: preferredImageSize });
    that.player.load({ initialImage: initialImage, asset: asset });
  });
};

PluginDemo.prototype.loadImage = function(image) {
  $.ajax({
    url: '/plugin_demo/image.json?angle=' + image
  }).done(function(data) {
    $('#placement_image').attr('src', data.data.attributes.url);
  });
}

///***
///Loading Progress
///***
$(document).on('atlas-load-start', '.atlas-events', function() {
  $('#progress_bar').progressbar({
    value: 0
  });
});

$(document).on('atlas-load-progress', '.atlas-events', function(element, data) {
  $('#progress_bar').progressbar("option", "value", data.progress * 100);
});

$(document).on('atlas-load-complete', '.atlas-events', function(element, data) {
  $('.pixelsquid-img-spinner-overlay').hide();
  $('.gps-slider').show();
});

$(document).on('atlas-image-changed', '.atlas-events', function(element, data) {
  var nextImage = data.nextImage;
  setTimeout(function() {
    if (nextImage === window.demo.player.getCurrentImageIndex()) {
      window.demo.loadImage(window.demo.player.getCurrentImageIndex());
    }

    if (window.demo.firstRotation) {
      analytics_plugin_demo_spin_object();
      $('.gps-slider').hide();
      if (!isMobile()) {
        $(window).joyride('nextTip');
      }
      window.demo.firstRotation = false;
    }
  }, 500);
});

window.demo = null;

$(document).ready(function() {
  window.demo = new PluginDemo();
});

function getPlayerSize() {
  var playerWidth = $('.atlas-container').css('width');
  var playerHeight = $('.atlas-container').css('height');
  var playerSize = Math.min(parseInt(playerHeight), parseInt(playerWidth));

  return playerSize;
}

function onResize() {
  var playerSize = getPlayerSize();

  if (window.demo && window.demo.player) {
    window.demo.player.resizeWindow(playerSize);
  }
}

function isMobile() {
  return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
}

///***
///Initialize the player and begin loading
///***
$(window).on('load', function() {
  //call on start
  onResize();

  $(window).on('resize', function() {
    //call when window resizes
    onResize();
  });

  if (isMobile()) {
    $('.desktop-intro').hide();
    $('.mobile-intro').show();

    $('.pre-demo').hide();
    $('.in-demo').show();
    window.demo.loadPlayer();
    analytics_plugin_demo_start_demo();

    $('.gallery-cells-hotspot').on('click', function(e) {
      analytics_plugin_demo_select_object();
      $('.pixelsquid-img-spinner').show();
      $('.atlas-container').show();
      $('.placement-image-container').show();
    });
  }
  else {
    $('.desktop-intro').show();

    $('#pixelsquid_walkthrough').joyride({
      scroll: false,
      autoStart: false,
      nextButton: false,
      template: {
        link: ''
      }
    });

    $('.start-demo').on('click', function() {
      analytics_plugin_demo_start_demo();
      $('.plugin-intro').hide("blind", { direction: "up" }, "slow", function() {
        $('body').animate({
          scrollTop: 0
        }, 1000);
        $('.in-demo').show('fade');
        $(window).joyride('restart');
        window.demo.loadPlayer();
      });
    });

    $('.gallery-cells-hotspot').on('click', function(e) {
      analytics_plugin_demo_select_object();
      $(window).joyride('nextTip');
      $('.pixelsquid-img-spinner').show();
      $('.atlas-container').show();
      $('.placement-image-container').show();
    });

    $('.end-demo').on('click', function(e) {
      $(window).joyride('nextTip');
      analytics_plugin_demo_end_demo();
      $('.plugin-intro').slideDown();
      $('body').animate({
        scrollTop: 0
      }, 1000);
    });
  }
});
