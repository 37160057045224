Q = require('q/q')

export class GalleryItem
  constructor: (@$element)->
    @productID = @$element.data('id')

  fetchQuickPreview: =>
    return Q(@$element) if @isLoaded()
    return @promise if @promise?

    deferred = Q.defer()

    $.ajax
      url: @quickPreviewUrl()
    .fail ->
      deferred.reject(@$element)
    .done =>
      deferred.resolve(@$element)

    @promise = deferred.promise

  quickPreviewUrl: ->
    parser = document.createElement('a')
    parser.href = window.location
    parser.pathname = "/png/#{@productID}/quick_preview.js"
    parser.search = ''
    parser.href

  isLoaded: ->
    @$element.find('.js-quick-preview-overlay-content').length > 0

  resetOverlayState: ->
    @$element.find('.btn-add-to-lightbox').removeClass('expanded')
    @$element.find('.lightbox-widget-body').addClass('hidden')
    @$element.find('.btn-add-to-lightbox .fa').removeClass('fa-caret-up').addClass('fa-caret-down')
