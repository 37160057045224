import Rails from '@rails/ujs'

export class PaymentFormHandler
  constructor: (@paymentMethodForm, @loadingModal, @type) ->
    throw new Error('paymentMethodForm must be specified') unless @paymentMethodForm
    throw new Error('loadingModal must be specified') unless @loadingModal
    throw new Error('type must be specified') unless @type

    # TODO: V2 version of stripe is not used anymore in the project, remove this
    if window.isStripeV2()
      $('#order-card-number').payment('formatCardNumber')
      $('#order-card-cvc').payment('formatCardCVC')
      $('#order-card-expiry').payment('formatCardExpiry')

    @validators =
      # TODO: V2 version of stripe is not used anymore in the project, remove this
      if window.isStripeV2()
        "#order-card-number":
          validator: $.payment.validateCardNumber
        "#order-card-cvc":
          validator: $.payment.validateCardCVC
        "#order-card-expiry":
          validator: @validExpiry
        "#billing-name":
          validator: @isTextPresent
        "#billing-address-1":
          validator: @isTextPresent
        "#billing-zip":
          validator: @isTextPresent
        "#billing-country_":
          validator: @isTextPresent
      else
        "#billing-name":
          validator: @isTextPresent

  validate: (field, validator) ->
    valid = validator(field.val())
    field.parent().toggleClass('has-error', !valid)
    return valid

  # TODO: V2 version of stripe is not used anymore in the project, remove this
  validExpiry: (value) ->
    data = $.payment.cardExpiryVal(value)
    $.payment.validateCardExpiry(data)

  isTextPresent: (value) ->
    check = (value || "").trim()
    !!check?.length

  validateForm: ->
    for field in Object.keys(@validators)
      @validate($(field), @validators[field].validator)

  showLoading: ->
    # Remove fade to prevent timing issue with hiding
    @loadingModal.removeClass('fade')
    @loadingModal.modal('show')

  hideLoading: ->
    @loadingModal.modal('hide')

  appendTokenAndSubmit: (token) ->
    @paymentMethodForm.append(
      $('<input type="hidden" name="' + @type + '[provider_card_token]">').val(token)
    )
    @triggerSubmit()

  handleStripeError: (message) ->
    $('#order-error-text').text(message)
    $('#order-error').show()
    $('#order-submit').prop('disabled', false)
    # Delay to prevent timing issue with modal show fade
    @hideLoading()

  getStripeToken: ->
    if window.isStripeV2()
      # TODO: V2 version of stripe is not used anymore in the project, remove this
      Stripe.card.createToken(@paymentMethodForm, (status, response) =>
        if (response.error)
          @handleStripeError(response.error.message)
        else
          @appendTokenAndSubmit(response.id)
      )
    else
      extraDetails =
        name: $('#billing-name').val().trim()

      window.stripeElements.createToken(extraDetails).then((result) =>
        if (result.error)
          @handleStripeError(result.error.message)
        else
          @appendTokenAndSubmit(result.token.id)
      )

  triggerSubmit: ->
    $('#order-submit').prop('disabled', true)
    Rails.fire(@paymentMethodForm[0], 'submit')

  process: (valid_field) ->
    if valid_field
      validationResult = @validateForm()
      $('#order-saved-payment-error').hide()

      if validationResult.filter((x) => !!x).length != validationResult.length
        if window.isStripeV2()
          $('#order-error-text').text('Please verify card details.')
        else
          $('#order-error-text').text('Please enter a cardholder name.')

        $('#order-error').show()
        $('#order-submit').prop('disabled', false)
      else
        $('#order-error').hide()
        $('#order-submit').prop('disabled', true)
        @showLoading()
        @getStripeToken()
    else
      @showLoading()
      @triggerSubmit()

$ ->
  paymentMethodForm = $('#new_payment_method')
  if paymentMethodForm.length
    paymentMethodFormHandler = new PaymentFormHandler(paymentMethodForm, $('#loadingModal'), "payment_method")

    $('#payment_method_button').on('click', (e) ->
      e.preventDefault()
      $('.account-flash-messages').hide('slow')
      cardFieldPresent = $('#order-card-number').length or $('#card-element').is(':visible')
      paymentMethodFormHandler.process(cardFieldPresent)
      return false
    )
