$ ->
  toggler = $('.period-toggler')
  parent = toggler.parent()
  leftValues = toggler.find('.period-toggle-switch-left')
  rightValues = toggler.find('.period-toggle-switch-right')
  btn = parent.parent().find('.pricing-btn')

  toggler.find('.toggle-switch-checkbox').on 'change', ->
    if this.checked
      price = rightValues.data('price')
      title = rightValues.data('title')
      subtitle = rightValues.data('subtitle')

      parent.find('.title').html(price)
      parent.find('.super-title').html(title)
      parent.find('.subtitle').html(subtitle)

      btn.attr('href', btn.data('primary-plan-link'))
      btn.removeClass(btn.data('secondary-plan-class'))
      btn.addClass(btn.data('primary-plan-class'))
    else
      price = leftValues.data('price')
      title = leftValues.data('title')
      subtitle = leftValues.data('subtitle')

      parent.find('.title').html(price)
      parent.find('.super-title').html(title)
      parent.find('.subtitle').html(subtitle)

      btn.attr('href', btn.data('secondary-plan-link'))
      btn.removeClass(btn.data('primary-plan-class'))
      btn.addClass(btn.data('secondary-plan-class'))
