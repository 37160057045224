$(function() {
  dataConfirmModal.setDefaults({
    title:       'Are you sure?',
    commit:      'Yes',
    cancel:      'Cancel',
    fade:         false,
    verifyClass: 'form-control',
    commitClass: 'btn-modal',
    cancelClass: 'btn-modal',
    closeMarkup: "<button type='button' class='close' data-dismiss='modal'>" +
                 "<span aria-hidden='true'>" +
                  +
                 "</span><span class='sr-only'>" +
                 "Close" +
                 "</span></button>"
  });
});
