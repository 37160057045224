Q = require('q/q')

export class ImageCache
  @isCached: (image)=>
    image.complete

  @imageCachedPromise: (index, image)=>
    return Q(image) if @isCached(image)

    deferred = Q.defer()
    $image = $(image)
    $image.on 'load', ->
      $image.off 'load'
      deferred.resolve($image)
    deferred.promise

  @cacheImages: (images)=>
    Q.all images.map(@imageCachedPromise)
