import Rails from '@rails/ujs'
import { PaymentFormHandler } from './payment_methods'

$ ->
  # TODO: Extract into common code for v2 and v3/Elements
  paymentForm = $('#new_order')

  loadingModal = $('#loadingModal')

  showLoading = ->
    # Remove fade to prevent timing issue with hiding
    loadingModal.removeClass('fade')
    loadingModal.modal('show')

  hideLoading = ->
    loadingModal.modal('hide')

  revealBilling = ->
    $('#new-payment-row .billing-row').addClass('expanded')
    $('#new-payment-row').slideDown()

  concealBilling = ->
    $('#new-payment-row .billing-row').removeClass('expanded')
    $('#new-payment-row').slideUp()

  selectNewCard = ->
    $('#paypal-order-wrapper').hide()
    $('#creditcard-order-wrapper').show()
    $('#saved-payment-radio-item').attr('checked', false)
    $('#pay-pal-radio-item').attr('checked', false)
    $('#saved-payment-id').attr('disabled', 'disabled')
    $('#new-payment-row .billing-row').addClass('expanded')
    $('input[name="order[provider_card_token]"]').removeAttr('disabled')
    revealBilling()

  selectSavedCard = ->
    $('#paypal-order-wrapper').hide()
    $('#creditcard-order-wrapper').show()
    $('#saved-payment-id').removeAttr('disabled')
    $('#pay-pal-radio-item').attr('checked', false)
    $('#new-payment-radio-item').attr('checked', false)
    $('input[name="order[provider_card_token]"]').attr('disabled', 'disabled')
    concealBilling()

  selectNewOrSavedCard = ->
    if $('#saved-payment-id')[0]?
      $('#saved-payment-radio-item').trigger('click')
    else
      $('#new-payment-radio-item').trigger('click')

  selectPayPal = ->
    $('#paypal-order-wrapper').show()
    $('#creditcard-order-wrapper').hide()
    $('#saved-payment-id').attr('disabled', 'disabled')
    $('#saved-payment-radio-item').attr('checked', false)
    $('#new-payment-radio-item').attr('checked', false)
    $('#pay-pal-radio-item').attr('checked', true)
    concealBilling()

  configurePaymentForm = ->
    new_payment_item = $('#new-payment-radio-item')
    pay_pal_item = $('#pay-pal-radio-item')
    saved_item = $('#saved-payment-radio-item')
    if new_payment_item.is(':checked')
      selectNewCard()
    else if pay_pal_item.is(':checked')
      selectPayPal()
    else if saved_item.is(':checked')
      selectSavedCard()
    else
      selectNewOrSavedCard()

  configureProductSelection = ->
    productSelected = document.location.href.match(new RegExp(/product_id=/))
    if productSelected?
      selectRow(0)
      $('.product-radio-input').trigger('click')
      selectNewOrSavedCard()
    else if $('.product-radio-input').is(':checked')
      if $('#pay-pal-radio-item').is(':checked')
        selectNewOrSavedCard()
    else
      $('#pay-pal-option-label').show()
      selected = $('.order-radio-input:checked')
      if selected.length > 0
        index = $(selected[0]).data('item')
        selectRow(index)
        toggleBilling(index)
      else
        selectRow(0)
        toggleBilling(index)

  paypalAcceptForm = $("form[data-agreement-prompt=true]")
  if paypalAcceptForm[0]?
    $('#orderPayPalModal').modal('show')

  $('#btn-confirm-pay-pal').click (e) ->
    e.preventDefault()
    $('#orderPayPalModal').modal('hide')
    showLoading()
    Rails.fire(paypalAcceptForm[0], 'submit')

  newPayPalOrder = (planID) ->
    showLoading()
    form = $("form[data-plan=#{planID}]")
    Rails.fire(form[0], 'submit')

  toggleBilling = (index) ->
    dataEl = $('#'+index)
    if dataEl.data('type') == 'product'
      $('#pay-pal-option-label').hide()
    else
      $('#pay-pal-option-label').show()

    if parseInt(dataEl.data('price')) > 0
      $('#saved-payment-row').show()
      $('.billing-row').show()
    else
      $('#saved-payment-row').hide()
      $('.billing-row').hide()
      $('#saved-payment-id').removeAttr('disabled')

  $('#pay-with-paypal').click (e) ->
    e.preventDefault()
    planInput = $('.subscription-radio-input:checked')[0]

    if planInput?
      if $(planInput).data('provider') == 'stripe'
        newPayPalOrder(gon.stripe_to_pp[planInput.value])
      else
        newPayPalOrder(planInput.value)
    else
      $('#order-error-text').text('Please select a plan option.')
      $('#order-error').show()

  $('.orders-option-row').click (e) ->
    id = $(this).data('item')
    type = $(this).data('type')
    dataEl = $('#'+id)

    if (type == 'product')
      pay_pal_item = $('#pay-pal-radio-item').attr('checked', false)
      $('#paypal-order-wrapper').hide()

    $('#promotion-code').attr('name', '')
    promotionData = dataEl.data('promotion')
    if typeof(promotionData) != 'undefined' && promotionData.length > 0
      $('#promotion-code').attr('name', 'order[promotion_code]')

    toggleBilling(id)
    selectRow(id)
    handleSavePaymentDisplay(id)

  selectRow = (index) ->
    ga_selected_product_cart_action('remove', 2, 'ecommerce', 'cart-remove')
    $('.radio-input').prop('checked', false)
    $('.order-radio-icon').removeClass('fa-dot-circle-o')
    $('.order-radio-icon').addClass('fa-circle-o')
    $('#'+index).prop('checked', true)
    if $('#'+index).data('type') == 'product'
      if $('#new-payment-radio-item').is(':checked')
        selectNewCard()
      else
        selectNewOrSavedCard()
    $('.orders-option-row').removeClass('selected')
    $('#order-row-'+index).addClass('selected')
    $('#order-radio-'+index).removeClass('fa-circle-o')
    $('#order-radio-'+index).addClass('fa-dot-circle-o')
    ga_selected_product_cart_action('add', 2, 'ecommerce', 'cart-add')

  handleSavePaymentDisplay = (index) ->
    if $('.order-radio-input[data-item="' + index + '"]').hasClass('subscription-radio-input')
      $('#save-payment-method-input').hide()
    else
      $('#save-payment-method-input').show()

  $('#js-print-button').click ->
    window.print()
    false

  $('#js-update-invoice-button').click()

  $('.orders-option-row').click (e) ->
    $(e.target).find('.subscription-radio-input').trigger('click')

  $('.radio-input').click (e) ->
    selectRow(e.target.id)

  $('.payment-radio-item').change (e) ->
    configurePaymentForm()

  orderPaymentForm = $('#new_order')
  if (orderPaymentForm.length)
    orderPaymentMethodFormHandler = new PaymentFormHandler(orderPaymentForm,  $('#loadingModal'), "order")

    $('#order-submit').on('click', (e) ->
      e.preventDefault()
      cardFieldPresent = (
        ($('#order-card-number').length and $('.billing-row').is(':visible')) or
        $('#card-element').is(':visible')
      )
      orderPaymentMethodFormHandler.process(cardFieldPresent)
      return false
    )

  configureProductSelection()
  configurePaymentForm()
