export class SpriteSpinnerPreview
  DEFAULT_OPTIONS =
    framerate: 10
    frameOffset: 600
    containerSelector: '#js-quick-preview-overlay'
    imagesSelector: '.js-spinner-sprite-display'

  constructor: (userOptions = {}) ->
    @options = Object.assign(DEFAULT_OPTIONS, userOptions)

  clearInterval: =>
    clearInterval(@turntableTimer) if @turntableTimer?

  start: (frameCount = 16, reverse = true)=>
    @stop()
    $spriteContainer = $(@options.containerSelector).find(@options.imagesSelector)
    startFrame = $spriteContainer.data('startFrame') || 1
    frames = if reverse then [frameCount..1] else [1..frameCount]
    index = frames.indexOf(startFrame)
    setSpinnerFrame = =>
      currentFrame = frames[index]
      offset = @options.frameOffset * (currentFrame - 1)
      $spriteContainer.css('background-position', "-#{offset}px 0px")
      index = (index + 1) % frames.length

    if $spriteContainer.length
      @turntableTimer = setInterval(setSpinnerFrame, 1000 / @options.framerate)

  stop: =>
    @clearInterval()
