class UserLightboxWidget
  constructor: ->
    @$galleryColumn = $('.ts-gallery-column')
    @widgetEnabled = true

  itemClickHandler: (element) ->
    $element = $(element)

    @productId = $element.data('productId')
    @lightboxId = $element.data('lightboxId')
    @quickPreview = $element.data('quickPreview')
    @widget = $element.closest('.lightbox-widget-body')
    @url = '/user_lightboxes/' + @lightboxId + '/products/' + @productId

    if @quickPreview
      @url += '?origin=quick_preview'

    action = $element.data('action') || 'add'

    switch action
      when 'add'
        @addToLightbox()
      when 'remove'
        @removeFromLightbox()
      else
        return

  disableWidget: ->
    @widgetEnabled = false
    @widget.addClass('disabled')
    @widget.find('.lightbox-status[data-lightbox-id=' + @lightboxId + ']').show()

  enableWidget: ->
    @widgetEnabled = true
    @widget.removeClass('disabled')
    @widget.find('.lightbox-status[data-lightbox-id=' + @lightboxId + ']').hide()

  addToLightbox: ->
    @disableWidget()
    that = this

    $.ajax(
      url: @url
      type: 'PUT'
      context: this
      success: ->
        $('.lightbox-added-mark[data-product-id=' + @productId + ']').removeClass('hidden')
      always: ->
        that.enableWidget()
    )

  removeFromLightbox: ->
    @disableWidget()
    @widget.find('.lightbox-remove-image[data-lightbox-id=' + @lightboxId + ']').hide()
    that = this

    $.ajax(
      url: @url
      type: 'DELETE'
      context: this
      success: ->
        updated_widget = $('.lightbox-widget-body[data-product-id=' + @productId + ']')
        count = parseInt(updated_widget.data('containing'))
        if count == 0
          $('.lightbox-added-mark[data-product-id=' + @productId + ']').addClass('hidden')
      error: ->
        @widget.find('.lightbox-remove-image[data-lightbox-id=' + @lightboxId + ']').show()
      always: ->
        that.enableWidget()
    )

  resizeLightboxWidget: ($widget) ->
    if @$galleryColumn.length
      newWidth = @$galleryColumn.width()
      newWidth -= parseInt(@$galleryColumn.css('margin-left'))
      newWidth -= parseInt(@$galleryColumn.css('margin-right'))
      newWidth -= 2

      $widget.css('width', newWidth)
      $widget.find('.lightbox-name').css('width', 0.75 * newWidth)

class UserLightboxManager

  editName: ->
    $('.lightbox-name').hide()
    $('.lightbox-name-input').text($('.lightbox-name').text()).show()
    @setEditBtnGroupVisible(false)
    $('.lightbox-save-btn-group').show()

  saveName: ->
    $element = $('.lightbox-name-input')
    lightboxId = $element.data('lightbox-id')
    url = '/user_lightboxes/' + lightboxId
    url += '?origin=lightbox_manager'

    new_name = $element.val()
    new_name = new_name.trim()

    if $('.lightbox-name').text() == new_name
      @cancelEdit()
      return true

    $('.lightbox-save-btn-group').find('.btn-lightbox-control').attr('disabled', 'disabled')

    # validate name
    if new_name.length == 0 || new_name.length > 50
      $('.lightbox-save-btn-group').find('.btn-lightbox-control').removeAttr('disabled')
      $('.lightbox-name-input').val($('.lightbox-name').text())
      $('[data-toggle="tooltip"]').tooltip('show')
      return false

    $.ajax(
      url: url
      data:
        display_name: new_name
      type: 'PATCH'
      dataType: 'text'
      context: this
      success: (data) ->
        $('[data-toggle="tooltip"]').tooltip('hide')
        $('.lightbox-name').text(new_name)
        $('.lightbox-name').show()
        $('.lightbox-name-input').hide()
        @setEditBtnGroupVisible(true)
        $('.lightbox-save-btn-group').hide()
      error: ->
        $('[data-toggle="tooltip"]').tooltip('show')
      always: ->
        $('.lightbox-save-btn-group').find('.btn-lightbox-control').removeAttr('disabled')
    )

  cancelEdit: ->
    $('.lightbox-name').show()
    $('.lightbox-name-input').hide()
    $('.lightbox-name-input').val($('.lightbox-name').text())
    @setEditBtnGroupVisible(true)
    $('.lightbox-save-btn-group').hide()
    $('[data-toggle="tooltip"]').tooltip('hide')

  removeFromLightbox: (element) ->
    $element = $(element)
    productId = $element.data('product-id')
    lightboxId = $element.closest('.lightbox-objects-container').data('lightbox-id')
    url = '/user_lightboxes/' + lightboxId + '/products/' + productId
    url += '?origin=lightbox_manager'

    $element.find('.lightbox-remove-status').show()
    $element.find('.lightbox-remove-btn-text').hide()

    $.ajax(
      url: url
      type: 'DELETE'
      success: ->
        $element.closest('.ts-gallery-column').hide('fast')
        current_value = parseInt($('.lightbox-objects-container').data('object-count'))
        $('.lightbox-objects-container').data('object-count', current_value - 1).trigger('dataChange')
    )

  setEditBtnGroupVisible: (visible) ->
    width = $(window).width()
    if width > 767
      if visible
        $('.lightbox-edit-btn-group').show()
      else
        $('.lightbox-edit-btn-group').hide()
    else
      if visible
        $('.lightbox-edit-btn-group-mobile').show()
      else
        $('.lightbox-edit-btn-group-mobile').hide()

updateProductStatusIndicators = ->
  product_ids = $('.ts-gallery-column').map ->
    $(this).data('id')
  .get()

  if product_ids.length > 0
    $.ajax(
      url: '/user_lightboxes/product_status'
      type: 'GET'
      data: { product_ids: product_ids }
      success: (data) ->
        for product_id in data['product_ids']
          $('.lightbox-added-mark[data-product-id=' + product_id + ']').removeClass('hidden')
    )

$ ->
  window.userLightboxManager = new UserLightboxManager()
  window.userLightboxWidget = new UserLightboxWidget()
  $widgetContainers = $('#js-quick-preview-overlay, .js-lightbox-container[data-quick-preview=true], #full-preview-lightbox-container')

  $widgetContainers.on 'click', '.btn-add-to-lightbox', ->
    $widget = $(this).closest('.js-lightbox-container').find('.lightbox-widget-body')
    userLightboxWidget.resizeLightboxWidget($widget)

    $caret = $(this).find('.fa')
    if ($caret.hasClass('fa-caret-down'))
      $caret.removeClass('fa-caret-down')
      $caret.addClass('fa-caret-up')
    else
      $caret.removeClass('fa-caret-up')
      $caret.addClass('fa-caret-down')

    if $(this).hasClass('expanded')
      $(this).removeClass('expanded')
      $widget.addClass('hidden')
      window.quickPreview?.galleryItems[$(this).data('product-id')]?.resetOverlayState()
      if $('#full-preview-lightbox-container').length == 0
        $widgetContainers.css('height', 'initial')
    else
      $(this).addClass('expanded')
      $widget.removeClass('hidden')
      if $('#full-preview-lightbox-container').length == 0
        $widgetContainers.css('height', $('.ts-gallery-column').height() + 220)

  $widgetContainers.on 'click', '.btn-create-lightbox', ->
    $(this).addClass('hidden')
    $(this).closest('.js-lightbox-container').find('.add-to-new-lightbox-form').removeClass('hidden')

  $widgetContainers.on 'submit', '.add-to-new-lightbox-form', ->
    $form = $(this).closest('.js-lightbox-container').find('.add-to-new-lightbox-form')
    $form.find('input').attr('readonly', true)
    $form.find('input[type=submit]').attr('disabled', 'disabled')
    $form.find('.btn-submit-new-lightbox').attr('disabled', 'disabled')
    $form.find('.btn-submit-new-lightbox .fa-spinner').removeClass('hidden')
    $form.find('.btn-submit-new-lightbox .fa-plus').addClass('hidden')

  $widgetContainers.on 'click', '.lightbox-item:not(.readonly)', ->
    userLightboxWidget.itemClickHandler(this)

  $lightboxObjectsContainer = $('.lightbox-objects-container:not(.readonly)')
  $lightboxObjectsContainer.on 'click', '.btn-remove-from-lightbox', (event) ->
    event.preventDefault()
    $(this).prop('disabled', true)
    userLightboxManager.removeFromLightbox(this)

  $lightboxObjectsContainer.on 'mouseenter', '.ts-gallery-column', ->
    $(this).find('.search-product-title').css('visibility', 'hidden')
    $(this).find('.lightbox-object-hover').show()
  $lightboxObjectsContainer.on 'mouseleave', '.ts-gallery-column', ->
    $(this).find('.lightbox-object-hover').hide()
    $(this).find('.search-product-title').css('visibility', 'visible')

  $lightboxObjectsContainer.on 'dataChange', ->
    current_value = parseInt($(this).data('object-count'))
    inflective = 'objects'
    inflective = 'object' if current_value == 1
    $('#results').text('(' + current_value + ' ' + inflective + ')')

  $('#edit-lightbox-name-input').on 'keypress', ->
    if event.which == 13
      userLightboxManager.saveName()

  if $('#js-quick-preview-overlay, .js-lightbox-container[data-quick-preview=true]').length
    if typeof gon != 'undefined' && !!gon.current_user_id
      updateProductStatusIndicators()

  unless $.cookie('hide-empty-library-modal') == "true"
    $('#emptyLibraryModal').modal('show')
    $.cookie('hide-empty-library-modal', true, { path: '/; SameSite=Lax', secure: true })
