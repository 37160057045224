import { getResolutionForView } from '../products/constants';
import { licensePopup } from './license-popup'

class Product
  constructor: ->
    @productId   = gon.productId
    @currentView = gon.initialView

  setCurrentView: (view)->
    @currentView = view
    @

  requestDownloadLink: (downloadType, fileFormat, spinnerFileId) ->
    resolution = getResolutionForView(@currentView)

    download_link = {
      atlas:              @currentView,
      authorization_type: downloadType,
      format:             fileFormat,
      product_id:         @productId,
      resolution:         resolution,
      spinner_file_id:    spinnerFileId
    }

    ga_download(@currentView, fileFormat, false)
    that = this

    $.ajax
      url: "/png/#{@productId}/download_links.js"
      type: 'POST'
      data:
        download_link: download_link
      dataType: 'script'
      success: (data, statusText, jsXHR)->
        data
      error: (jsXHR, statusText, error)->
        that.handleDownloadError(error)

  downloadUrl: (url) ->
    if url
      @enableDownloadButtons()
      unless @isiOSUserAgent()
        if $('#downloadIFrame').length == 0
          iframe = document.createElement 'iframe'
          iframe.id = 'downloadIFrame'
          iframe.style.display = 'none'
          $('body').append iframe

        document.getElementById('downloadIFrame').src = url

  pushState: ->
    if @currentView
      history.replaceState({view: @currentView }, '', '?image=' + @currentView)

  startDownloadAction: (downloadType, fileFormat)->
    primaryFileFormat = fileFormat.split('-')[0]
    downloadButton = $('*[data-download-type="' + downloadType + '"][data-file-format="' + primaryFileFormat + '"].btn')
    twiddleButton = $('*[data-file-format="' + primaryFileFormat + '"].download-twiddle')
    $('.download-buttons .dropdown-menu').addClass('hidden')
    downloadButton.addClass('hidden')
    twiddleButton.addClass('disabled')

  enableDownloadButtons: ->
    $('.download-twiddle').removeClass('disabled')
    $('.download-buttons .dropdown-menu, .download, #formatDropdown').removeClass('hidden')

  handleRedirectActions: ->
    action = $.cookie('action')
    action = if action then JSON.parse(action) else null

    if action
      $.removeCookie('action')
      @startDownloadAction(action.downloadType, action.fileFormat)
      licensePopup.showEditorialPopupForPurchase((agree)=>
        if agree
          @requestDownloadLink(action.downloadType, action.fileFormat, action.spinnerFileId)
      )

  handleDownloadError: (error) ->
    @enableDownloadButtons()
    $('#jsDownloadErrorModal').modal('show');

  isiOSUserAgent: ->
    userAgent = navigator.userAgent
    !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i)

$ ->
  window.product = new Product()
  $('#product-buy', '#option_purchase_png').on 'click', (e) ->
    $('#purchaseOptionsModal').modal('show')
    ga_add_product_impressions('detail', 1, 'ecommerce', 'pricing-popup')
    e.preventDefault()

  if gon.showPricing && location.pathname.match(/^\/png\//)
    if gon.userLoggedIn
      $('#product-buy', '#option_purchase_png').trigger('click')
    else
      location.href='/pricing'

  $('#editorialPopup').on 'hide.bs.modal', (e) ->
    window.product.enableDownloadButtons()

  $('#action_option_free_png').prop('checked', true)
  $('#action_option_subscribe').prop('checked', false)

  $('.download-options input[type="radio"]').on 'click', (e) ->
    if $(this).val() == 'free_png'
      $('#download-action-button').show()
      $('#purchase-action-button').hide()
      $('#subscribe-action-button').hide()
    else if $(this).val() == 'purchase_png'
      $('#download-action-button').hide()
      $('#purchase-action-button').show()
      $('#subscribe-action-button').hide()
    else
      $('#download-action-button').hide()
      $('#purchase-action-button').hide()
      $('#subscribe-action-button').show()
