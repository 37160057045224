resizeFreeProductsLightboxScroller = ->
  $selector = $('.free-products-lightbox-products-scroller')
  if $(window).width() > 991
    $selector.css('height', ($('.free-products-lightbox-main-image').css('height')))
  else
    $selector.css('height', '')
$ ->
  $(window).on 'load resize', ->
    resizeFreeProductsLightboxScroller()

  $addButtons = $('.btn-free-products-lightbox-cta, .btn-free-products-lightbox-cta-mobile').not('.no-twiddle')
  $addButtons.on 'click', ->
    $(this).html('<i class="fa fa-spinner fa-spin fa-fw"></i>')
