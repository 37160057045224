function LicensePopup() {
  this._onEditorialClose = null;
}

LicensePopup.prototype.showEditorialPopup = function(showEditorialAcceptance) {
  if (showEditorialAcceptance == null) {
    showEditorialAcceptance = true;
  }

  $('#editorialPopup .modal-agreement').toggle(showEditorialAcceptance);
  $('#editorialPopup .editorial-close-agree').toggle(showEditorialAcceptance);

  $('#hideEditorialWarning').prop('checked', $.cookie('hide-editorial-warning') == '1');
  $('#editorialPopup').modal('show');
};

LicensePopup.prototype.hideEditorialPopup = function(agree) {
  if ($('#hideEditorialWarning').is(':checked')) {
    $.cookie('hide-editorial-warning', 1, { expires: 365, path: '/; SameSite=Lax', secure: true });
  } else {
    $.cookie('hide-editorial-warning', 0, { expires: 365, path: '/; SameSite=Lax', secure: true });
  }
  $('#editorialPopup').modal('hide');

  if (this._onEditorialClose) {
    this._onEditorialClose(agree);
    this._onEditorialClose = null;
  }
};

LicensePopup.prototype.showEditorialPopupForPurchase = function(callback) {
  if (($.cookie('hide-editorial-warning') != '1') && (gon.licenses.indexOf('editorial') > -1)) {
    this._onEditorialClose = callback;
    licensePopup.showEditorialPopup();
  } else {
    callback(true);
  }
};

LicensePopup.prototype.setLicensePopupHandlers = function() {
  $('.editorial-popup-link').on('click', function() {
    licensePopup.showEditorialPopup(false);
  });

  $('.editorial-close-agree').on('click', function() {
    licensePopup.hideEditorialPopup(true);
  });

  $('.editorial-close-disagree').on('click', function() {
    licensePopup.hideEditorialPopup(false);
  });

  $('#editorialPopup').on('hidden.bs.modal', function() {
    licensePopup.hideEditorialPopup(false);
  });
};

var licensePopup = null;
$(function() {
  licensePopup = new LicensePopup();
  licensePopup.setLicensePopupHandlers();
});

export { licensePopup };
